import weblogo from "../asset/jay ambe logo-1.png";
import virgin_kraft_1 from "../asset/virgin_kraft_1.png";
import virgin_kraft_2 from "../asset/virgin_kraft_2.png";
import paper_bag from "../asset/paper_bag_1.png";
import duplex_board1 from "../asset/duplex_board_1.png";
import duplex_board2 from "../asset/duplex_board_2.png";
import duplex_board3 from "../asset/duplex_board_3.png";
import duplex_board4 from "../asset/duplex_board_4.png";
import duplex_board5 from "../asset/duplex_board_5.png";
import duplex_board6 from "../asset/duplex_board_6.png";
import virgin_kraft_liner1 from "../asset/virgin_kraft_liner_1.png";
import virgin_white_liner_top1 from "../asset/virgin_white_liner_top1.png";
import virgin_white_liner_top2 from "../asset/virgin_white_liner_top2.png";
import indian_recycle_kraft_paper1 from "../asset/indian_recycle_kraft_paper1.png";
import indian_recycle_kraft_paper2 from "../asset/indian_recycle_kraft_paper2.png";
import indian_recycle_kraft_paper3 from "../asset/indian_recycle_kraft_paper3.png";
import koren_shade1 from "../asset/Koren_shade1.png";
import kraft_paper1 from "../asset/kraft_paper1.png";
import kraft_paper2 from "../asset/kraft_paper2.png";
import kraft_paper3 from "../asset/kraft_paper3.png";
export default { weblogo,virgin_kraft_1,virgin_kraft_2,paper_bag,duplex_board1,duplex_board2,duplex_board3,duplex_board4,duplex_board5,duplex_board6,virgin_kraft_liner1,virgin_white_liner_top1,virgin_white_liner_top2,indian_recycle_kraft_paper1,indian_recycle_kraft_paper2,indian_recycle_kraft_paper3,koren_shade1,kraft_paper1,kraft_paper2,kraft_paper3 };
